.image-building{
  background: linear-gradient(rgba(0,0,0,0.6),rgba(0,0,0,0.5)),url('../Assets/Building&ConstructionBanner1.jpg');
  background-size: cover, cover;
  background-repeat: no-repeat;
  background-position: center;
  color: azure;
  
  
}
.carparkdeck-image{
  background: linear-gradient(rgba(0,0,0,0.6),rgba(0,0,0,0.4)),url('../Assets/carparkdeck2.jpg');
  background-size: cover, cover;
  background-repeat: no-repeat;
  background-position: center;
  color: azure;
}

/* Home section */

.tech-conatiner-building {
    max-width: 1400px;
    margin: 2rem auto;
  }
  
  .separator-building {
    display: flex;
    align-items: center;
  }
  
  .separator-building .line-building {
    height: 2px;
    flex: 1;
    background-color: #b00c0c;
  }
  
  .separator-building h3 {
    padding: 0 2rem;
    font-weight: bolder;
  }

  .Bedliner-image:hover{
    position: relative;
    box-shadow: 0 10px 20px rgba(44, 43, 43, 0.9);
    transition: 0.6s ; 
  }