.image-oilandgas {

  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../Assets/oilandgas1.jpg');
  background-size: cover, cover;
  background-repeat: no-repeat;
  background-position: center;
  color: azure;

}
.image-oilandgas-second{
  
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../Assets/oilandgas4.jpg');
  background-size: cover, cover;
  background-repeat: no-repeat;
  background-position: center;
  color: azure;
}


/* Home section */

.tech-conatiner-oilandgas {
  max-width: 1400px;
  margin: 2rem auto;
}

.separator-oilandgas {
  display: flex;
  align-items: center;
}

.separator-oilandgas .line-oilandgas {
  height: 2px;
  flex: 1;
  background-color: #b00c0c;
}

.separator-oilandgas h3 {
  padding: 0 2rem;
  font-weight: bolder;
}