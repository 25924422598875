.image-water {
  background: linear-gradient(rgba(0,0,0,0.6),rgba(0,0,0,0.5)),url('../Assets/WaterandwastewaterBanner.jpg');
  background-size: cover, cover;
  background-repeat: no-repeat;
  background-position: center;
  color: azure;
  

}
.benefit-image{
  background: linear-gradient(rgba(0,0,0,0.6),rgba(0,0,0,0.5)),url('../Assets/wastewater3.png');
  background-size: cover, cover;
  background-repeat: no-repeat;
  background-position: center;
  color: azure;
  

}
/* Home section */

.tech-conatiner-water {
    max-width: 1400px;
    margin: 2rem auto;
  }
  
  .separator-water {
    display: flex;
    align-items: center;
  }
  
  .separator-water .line-water {
    height: 2px;
    flex: 1;
    background-color: #b00c0c;
  }
  
  .separator-water h3 {
    padding: 0 2rem;
    font-weight: bolder;
  }

