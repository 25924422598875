.image-bedliners {
  background: linear-gradient(rgba(0,0,0,0.6),rgba(0,0,0,0.5)),url('../Assets/BedlinersBanner.jpg');
  background-size: cover, cover;
  background-repeat: no-repeat;
  background-position: center;
  color: azure;
  

}
.key-benefit{
  background: linear-gradient(rgba(0,0,0,0.6),rgba(0,0,0,0.8)),url('../Assets/Bedliners8.jpg');
  background-size: cover, cover;
  background-repeat: no-repeat;
  background-position: center;
  color: azure;

}

/* Home section */

.tech-conatiner-bedliners {
  max-width: 1400px;
  margin: 2rem auto;
}

.separator-bedliners {
  display: flex;
  align-items: center;
}

.separator-bedliners .line-bedliners {
  height: 2px;
  flex: 1;
  background-color: #b00c0c;
}

.separator-bedliners h3 {
  padding: 0 2rem;
  font-weight: bolder;
}