
/* ================================
======== Page Not Found ========== */

.pnf{
    display: flex;
    min-height: 65vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .pnf-title{
    font-size: 40px;
    font-weight: 700;
  }
  
  .pnf-heading{
    font-weight: normal;
    font-size: medium;
  }
  .pnf-btn{
    color: black;
    
    text-decoration: none;
    padding: 10px;
    margin-top: 10px;
  }
  .pnf-btn:hover{
    color: white;
    background-color: black;
  }
  
  