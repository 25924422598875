/* data sheets section */

/* .card-datasheets {
    height: 120px;
    padding-top: 1.5em;
    box-shadow: none;
    text-decoration: none;
    color: black;
} */

/* .card-datasheets:hover {
    position: relative;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.4);
    transition: 0.4s ease-in-out;
} */

/* Home section */

.tech-container{
    max-width: 1400px;
    margin: 2rem auto;
}

.separator-service {
    display: flex;
    align-items: center;
}

.separator-service .line-service{
    height: 2px;
    flex: 1;
    background-color: #f69a0b;
}

.separator-service h3 {
    padding: 0 2rem;
    font-weight: bolder;
}