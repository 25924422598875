.image-floor {
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('../Assets/IndustrialFlooringCard.jpg');
  background-size: cover, cover;
  background-repeat: no-repeat;
  background-position: center;
  color: azure;


}
.UniversityMedicalLaboratories{
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('../Assets/industrialfloor1.jpg');
  background-size: cover, cover;
  background-repeat: no-repeat;
  background-position: center;
  color:#ffff;

}

/* Home section */

.tech-conatiner-floor {
  max-width: 1400px;
  margin: 2rem auto;
}

.separator-floor {
  display: flex;
  align-items: center;
}

.separator-floor .line-floor {
  height: 2px;
  flex: 1;
  background-color: #b00c0c;
}

.separator-floor h3 {
  padding: 0 2rem;
  font-weight: bolder;
}