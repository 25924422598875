/* Home section */

.tech-conatiner {
  width: 150px;
 
}

.separator-homepage {
  display: flex;
  /* align-items: center; */
 
}

.separator-homepage .line-homepage {
  height: 2px;
  flex: 1;
  background-color: #7b0808;
}

.separator-homepage h3 {
  padding: 0 2rem;
  font-weight: bolder;
}




/* cards */

.card-container {
  width: 17rem;
  height: 16rem;
  position: relative;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
  overflow: hidden;

}

.card-first {
  width: 100%;
  height: 100%;
  border-radius: inherit;

}

.card-first .front-content {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1)
}

.card-first .front-content p {
  font-size: 32px;
  font-weight: 700;
  opacity: 1;
  background: linear-gradient(-45deg, #f89b29 0%, #ff0f7b 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1)
}

.card-first .content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 10px;
  background: linear-gradient(-45deg, #1e1e1d 0%, #3d3d3b 100%);
  color: #e8e8e8;
  padding: 20px;
  line-height: 1.5;
  border-radius: 5px;
  pointer-events: none;
  transform: translateY(96%);
  transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
}

.card-first .content .heading {
  font-size: 20px;
  font-weight: 700;
}

.card-first:hover .content {
  transform: translateY(0);
}

.card-first:hover .front-content {
  transform: translateY(-30%);
}

.card-first:hover .front-content p {
  opacity: 0;
}



.welcome-section{
  background:linear-gradient(rgb(111, 111, 111),rgb(51, 50, 50));
}