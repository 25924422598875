.image-roofing{
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.5)), url('../Assets/roofing4.jpg');
  background-size: cover, cover;
  background-repeat: no-repeat;
  background-position: center;
  color: azure;
    
    
}
.image-roofing-second{
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.5)), url('../Assets/roofing8.jpg');
  background-size: cover, cover;
  background-repeat: no-repeat;
  background-position: center;
  color: azure;
}
/* Home section */

.tech-conatiner-roofing {
    max-width: 1400px;
    margin: 2rem auto;
  }
  
  .separator-roofing {
    display: flex;
    align-items: center;
  }
  
  .separator-roofing .line-roofing {
    height: 2px;
    flex: 1;
    background-color: #b00c0c;
  }
  
  .separator-roofing h3 {
    padding: 0 2rem;
    font-weight: bolder;
  }

