/* data sheets section */

.card-datasheets {
    height: 120px;
    padding-top: 1.5em;
    box-shadow: none;
    text-decoration: none;
    color: black;
  }
  
  .card-datasheets:hover {
    position: relative;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.4);
    transition: 0.4s ease-in-out;
  }

  /* line tech section */

.tech-conatiner {
    max-width: 1400px;
    margin: 2rem auto;
  }
  
  .separator {
    display: flex;
    align-items: center;
  }
  
  .separator .line {
    height: 2px;
    flex: 1;
    background-color: #e00b0b;
  }
  
  .separator h3 {
    padding: 0 2rem;
    font-weight: bolder;
  }
  