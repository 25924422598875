.image-marine{
  background: linear-gradient(rgba(0,0,0,0.6),rgba(0,0,0,0.5)),url('../Assets/marine5.jpg');
  background-size: cover, cover;
  background-repeat: no-repeat;
  background-position: center;
  color: azure;
    
    
}
.background-image-marine{
  background: linear-gradient(rgba(0,0,0,0.2),rgba(0,0,0,0.4)),url('../Assets/marine7.jpg');
  background-size: cover, cover;
  background-repeat: no-repeat;
  background-position: center;
  
  color: azure;
}

/* Home section */

.tech-conatiner-marine {
    max-width: 1400px;
    margin: 2rem auto;
  }
  
  .separator-marine {
    display: flex;
    align-items: center;
  }
  
  .separator-marine .line-marine {
    height: 2px;
    flex: 1;
    background-color: #b00c0c;
  }
  
  .separator-marine h3 {
    padding: 0 2rem;
    font-weight: bolder;
  }