* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}
body {
  font-family: "Inter", sans-serif;
}

/* Header section  */
/* .heading{
  font-weight:900;
  letter-spacing:1px;
  
}
.navbar{
  position:fixed;
  z-index: 5;
  width: 100%;
  box-shadow: 0 8px 6px -10px gray;
  --webkit-box-shadow: 0 8px 6px -10px gray;
  border-bottom: 0.1px  !important ;
}
.nav-link{
  font-weight: 600 !important;
}


.nav-link.active{
  border-bottom: 2px solid black;
} */

/* headersection 2*/
header {
  position: fixed;
  width: 100%;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 150px;
  padding: 0 2rem;
  background-color: #ffffff;
  color: #212121;
}
.logo-image {
  width: 100%;
  height: 8.5rem;
  
  
}
@media only screen and (max-width: 600px) {
  .logo-image{
    width: 100%;
    height: auto;
  }
}

nav a {
  margin: 0 1rem;
  text-decoration: none;
  color: #212121;
  font-weight: bolder;
  font-size: 14px;
}

nav a.active {
  border-bottom: 2px solid black;
}

nav a:hover {
  border-bottom: 2px solid black;
}

header .nav-btn {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: #212121;
  visibility: hidden;
  opacity: 0;
  font-size: 1.8rem;
}

@media only screen and (max-width: 1024px) {
  header .nav-btn {
    visibility: visible;
    opacity: 1;
  }

  header nav {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    z-index: 2;
    /* background-color: #878f8f; */
    background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.6)),
      url("./Assets/Banner.png");
    background-size: cover, cover;
    background-repeat: no-repeat;
    background-position: center;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    transition: 1s;
    transform: translateY(-100vh);
  }

  header .responsive_nav {
    transform: none;
  }

  nav .nav-close-btn {
    position: absolute;
    top: 2rem;
    right: 2rem;
    color: aliceblue;
  }

  nav a {
    color: #ffff;
    font-size: 1.5rem;
  }
}

/* Footer section  */
.footer {
  color: #ffffff;
  font-weight: 400;
  line-height: 18px;
  padding: 25px;
  background-color: rgb(17, 64, 69);
}
.footer-logo{
  display: flex;
  justify-content: end;
  
}
@media (max-width:760px){
.footer-logo{
  display: flex;
  justify-content: center;

}
}