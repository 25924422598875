.image-military{
  background: linear-gradient(rgba(0,0,0,0.6),rgba(0,0,0,0.5)),url('../Assets/militarybanner.jpg');
  background-size: cover, cover;
  background-repeat: no-repeat;
  background-position: center;
  color: azure;
    
}
.boat-section{
  background: linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)),url('../Assets/defense2.1.jpg');
  background-size: cover, cover;
  background-repeat: no-repeat;
  background-position: center;
  color: azure;
}

/* Home section */

.tech-conatiner-military {
    max-width: 1400px;
    margin: 2rem auto;
  }
  
  .separator-military {
    display: flex;
    align-items: center;
  }
  
  .separator-military .line-military {
    height: 2px;
    flex: 1;
    background-color: #b00c0c;
  }
  
  .separator-military h3 {
    padding: 0 2rem;
    font-weight: bolder;
  }